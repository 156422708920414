
.myCardMedia {
    position: relative;
    width: 100%; /* This will make the div take up the full width of its parent container */
    height: 93vh; /* This will make the div take up the full height of the viewport */
    background-size: cover;
    background-position: center;
}


.App-overlay-text {
    position: absolute; /* This positions the text relative to the image */
    top: 40%; /* This positions the top-left corner of the text in the center of the image */
    left: 50%; /* This positions the top-left corner of the text in the center of the image */
    transform: translate(-50%, -50%); /* This centers the text in the image */
    z-index: 1;
    background: rgba(45, 49, 66, 0.9);
    padding: 20px;
    color: white;
    margin-left: auto;
    margin-right: auto;
    max-width: 120%;
}

.App-Media {
    position: relative;
}