/* GalaxyScene.css */

.galaxy-scene {
    width: 100%;
    height: 60vh;
    position: relative;
  }
  
  .ui-overlay {
    position: absolute;
    top: 5%;
    right: 5%;
    width: 20%;
    height: 90%;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 10px;
    border-radius: 5px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .ui-overlay button {
    align-self: flex-end;
    background-color: transparent;
    border: none;
    font-size: 1.2em;
    cursor: pointer;
    color: #000;
  }
  
  .ui-overlay div {
    flex-grow: 1;
    overflow-y: auto;
  }
  